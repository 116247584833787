import React from "react"

const ID = "elfsight-app-f9c34545-b2b1-45c7-b8b7-62b8f068e295"

export const GoogleReviews = () => (
  // This component is handled by a script tag outside React so we don't want it to hydrate
  <div
    className="container elfsight-widget-container"
    dangerouslySetInnerHTML={{
      __html: `<div class="${ID}" data-elfsight-app-lazy></div>`,
    }}
    suppressHydrationWarning
  />
)
