import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./index.css"
import "../components/style.css"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import { GoogleReviews } from "../components/GoogleReviews"
import ConversionLink from "../components/conversion-link"
import { conversionTypes } from "../integration/gtag"

const IndexPage = () => {
  const { title, phone } = useSiteMetadata()
  // const [showCovidToast, setShowCovidToast] = React.useState(true)
  const images = useStaticQuery(graphql`
    query {
      emblem: file(relativePath: { eq: "emblem.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      license: file(relativePath: { eq: "yoramlocksmithcertificate.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      <Layout showSlideshow>
        <SEO title={title} />
        <div className="row">
          <div className="col-12 text-center pb-0 mx-auto">
            <GoogleReviews />
            <h2>זקוקים ל...</h2>
            <ul
              className="h4 font-weight-bold list list-centered d-sm-flex flex-wrap mx-auto"
              style={{ maxWidth: 800 }}
            >
              <li className="col-sm">פריצת בית או רכב?</li>
              <li className="col-sm">התקנת מנעול?</li>
              <li className="col-sm">החלפת צילינדר?</li>
            </ul>
            <br />
            <h2>תקראו למנעולן...</h2>
            <div className="d-sm-flex mx-auto" style={{ maxWidth: 800 }}>
              <div
                className="mx-auto h4 font-weight-bold col-sm"
                style={{ flex: "0 0 90px", width: "90px" }}
              >
                <Img
                  fluid={images.emblem.childImageSharp.fluid}
                  alt="מנעול מגן"
                />
                <h3 className="checked">מקצועי</h3>
              </div>
              <br />
              <div
                className="mx-auto h4 font-weight-bold col-sm"
                style={{ flex: "0 0 90px", width: "90px" }}
              >
                <Img
                  fluid={images.emblem.childImageSharp.fluid}
                  alt="מנעול מגן"
                />
                <h3 className="checked">אמין</h3>
              </div>
              <br />
              <div
                className="mx-auto h4 font-weight-bold col-sm"
                style={{ flex: "0 0 90px", width: "90px" }}
              >
                <Img
                  fluid={images.emblem.childImageSharp.fluid}
                  alt="מנעול מגן"
                />
                <h3 className="checked">מוסמך</h3>
              </div>
            </div>
            <br />
            <h2>{'מוסמך ע"י חבר המנעולנים בישראל'}</h2>
            <div
              className="mx-auto h4 font-weight-bold col-sm"
              style={{
                flex: "0 0 350px",
                maxWidth: "350px",
                borderRadius: "12px",
                overflow: "hidden",
                boxShadow: "#5a5a5a 1px 1px 8px 0px",
                padding: "0",
                imageRendering: "-webkit-optimize-contrast",
              }}
            >
              <Img
                fluid={images.license.childImageSharp.fluid}
                alt='מוסמך ע"י חבר המנעולנים בישראל'
              />
            </div>
          </div>
          <div className="col-12 left-content">
            <div className="row">
              <div className="col-sm-6">
                <h2 role="heading" aria-level="1">
                  {title}
                </h2>
                <p>
                  <strong>{title}</strong> הוא כאן בשבילכם. אנו חברת שירותי
                  מנעולנות מורשים מטעם חבר המנעולנים בישראל, המשרתים את אזור
                  המרכז, הרצליה והשרון. אנו מספקים מגוון רחב של שירותי מנעולנות
                  למגורים, מסחר וחירום במחירים הוגנים. כמו כן, אנו מספקים שירות
                  לפריצת רכב, בית ומשרד נעול ופריצת כספות. עיין{" "}
                  <Link to="services">בדף השירותים שלנו</Link> לקבלת רשימה של
                  השירותים המוצעים.
                </p>
              </div>
              <div className="col-sm-6">
                <h2>צור קשר</h2>
                <p>
                  זקוקים לשירות מנעולן אמין או שיש לכם שאלות לגבי השירותים שאנו
                  מציעים? אנחנו כאן בשבילכם. לקבלת מענה מהיר יותר אנו ממליצים
                  לפנות אלינו בטלפון במספר{" "}
                  <ConversionLink
                    href={`tel:${phone}`}
                    conversionId={conversionTypes.phoneHomepageCopy}
                  >
                    {phone}
                  </ConversionLink>{" "}
                  אבל אתם גם מוזמנים <Link to="contact">לפנות אלינו במייל</Link>{" "}
                  דרך דף יצירת הקשר שלנו. נשמח לשמוע מכם!
                </p>
              </div>
            </div>
            <div>
              <h3 className="text-center">
                לאמינות והביטחון המגיעים לכם, התקשרו למנעולן המקומי.
              </h3>
            </div>
            <br />
            <div>
              <p>
                אנו יודעים ששירותי המנעולן הנכונים חשובים על מנת להעניק
                ללקוחותינו ביטחון ושלווה ומתייחסים לכך במלוא המקצועיות הראויה.
                בין אם אתם נעולים מחוץ לבית, זקוקים להחלפת מנעולים או כל שירות
                אחר שלנו, נשמח להיות המנעולן שיעניק את השירות האיכותי המגיע לכם
                במחיר הוגן.
              </p>
            </div>
          </div>
        </div>
      </Layout>
      {/* <div className={`covid-toast ${showCovidToast ? "" : "hide"}`}>
        <div
          className="toast"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="toast-header">
            <strong>
              <Link to="covid">בטיחות לקוחותינו בזמן הקורונה</Link>
            </strong>
            <br />
            <button
              type="button"
              className="ml-2 mb-1 close"
              aria-label="Close"
              onClick={() => setShowCovidToast(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="toast-body d-none d-sm-block">
            אנחנו יודעים שאלה זמנים חסרי תקדים. תקראו מה אנחנו עושים כדי להגן על
            בטיחותכם תוך המשך מתן שירות מעולה.{" "}
            <Link to="covid">בטיחות לקוחותינו בזמן הקורונה</Link>.
          </div>
        </div>
      </div> */}
    </>
  )
}

export default IndexPage
